<template>
  <div class="account-wrap" :class="route.fullPath.includes('search') ? 'account-grid' : ''">
    <search-account v-if="route.fullPath.includes('search')"></search-account>
    <account-detail v-if="route.fullPath.includes('detail')"></account-detail>
    <account-list v-else></account-list>
  </div>
</template>
<script>
import { defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useMutations, useActions } from '@/utils/mapStore';
export default {
  name: 'manage-account',
  components: {
    AccountList: defineAsyncComponent(() => import('@/components/account/AccountList.vue')),
    SearchAccount: defineAsyncComponent(() => import('@/components/account/SearchAccount.vue')),
    AccountDetail: defineAsyncComponent(() => import('@/components/account/AccountDetail.vue')),
  },
  setup() {
    const route = useRoute();
    const { doGetStoreList, doGetPermissionList } = useActions('common', ['doGetStoreList', 'doGetPermissionList']);
    doGetStoreList();
    doGetPermissionList();
    //離開清空vuex
    const RESET = useMutations('account', ['RESET']);
    watch(
      () => route.path,
      () => {
        if (!route.path.includes('/account/list')) RESET();
      }
    );
    return { route };
  },
};
</script>
<style lang="scss" scoped>
.account-wrap {
  width: 100%;
  height: 100%;
}
.account-grid {
  display: grid;
  grid-template-rows: 96px auto;
  grid-gap: 16px;
}
</style>
